var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"650px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"action"},[_c('div',{staticClass:"action__header"},[_c('div',{staticClass:"b"},[_c('img',{staticClass:"i",attrs:{"src":require('@/assets/actions-update-customer.svg')}}),_c('span',{staticClass:"t"},[_vm._v("Create Customer")])]),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"action__content",style:(_vm.isSDK ? 'background-color:#F9FAFA' : 'background-color:#f8f7f4')},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"action-title"},[_vm._v("CUSTOMER")])]),_c('span',{staticClass:"action-description"},[_vm._v(" Add a new customer to your records. "),_c('br'),_vm._v(" Customers will be issued a NUBAN for all subsequent invoices and payments to your organization. ")]),_c('div',{staticClass:"a-wrapper"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","color":"primary","label":"Customer Type","items":_vm.customerType,"hide-details":"auto","placeholder":"Customer Type"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'input' || i.type === 'text'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"First Name","outlined":"","hide-details":"auto"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'input' || i.type === 'text'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Last Name","outlined":"","hide-details":"auto"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'email' || i.type === 'input'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Email","outlined":"","hide-details":"auto"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(function (i) { return i.type === 'digits'; })) ||
                [],"item-text":"label","item-value":"key","placeholder":"Phone","outlined":"","hide-details":"auto"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1)],1),(_vm.form.type === 'Company')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'text' || i.type === 'select'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Company Name","outlined":"","hide-details":"auto"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1)],1):_vm._e()],1),_c('div',{staticClass:"bottom"},[_c('v-btn',{attrs:{"large":"","color":"primary","outlined":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel ")],1),_c('v-btn',{attrs:{"large":"","disabled":!_vm.canAddToWorkflow,"color":_vm.isSDK ? '#19283D' : 'primary',"dark":_vm.isSDK,"elevation":"0"},on:{"click":_vm.addToWorkflow}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" Add to workflow ")],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }