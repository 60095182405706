<template>
  <div ref="action">
    <div class="vertical-line" v-if="!isSDK"></div>

    <div class="form-trigger">
      <div
        class="exception"
        :style="{
          transform: isSDK ? 'none' : 'translateY(-130%)',
          background: isSDK ? '#d9dee1' : '#f6f3ee',
        }"
      >
        <button
          :class="{ active: exceptionValue }"
          @click="exceptionValue = true"
        >
          <span></span> TRUE
        </button>
        <button
          :class="{ active: !exceptionValue }"
          @click="exceptionValue = false"
        >
          <span></span> FALSE
        </button>
      </div>
      <div
        class="header"
        @click="!isSDK ? (showTriggers = !showTriggers) : undefined"
      >
        <span class="title">
          <template v-if="exceptionValue"> Actions to Execute </template>
          <template v-else> Conditions Failed </template>
        </span>

        <v-btn color="primary" icon v-if="!isSDK">
          <v-icon size="33" v-if="!showTriggers">mdi-chevron-down</v-icon>
          <v-icon size="33" v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </div>

      <div v-if="showTriggers">
        <span class="text">
          <!-- Choose actions to be performed by this workflow, when the above
          conditions match the data received. -->
          <template v-if="exceptionValue">
            Choose actions to be performed by this workflow, only when the above
            conditions match the data received from the trigger - i.e.
            conditions are True.
          </template>
          <template v-else>
            Choose the action to be executed when the above conditions do not
            match the data received from the trigger - i.e. conditions are
            False.
          </template>
        </span>

        <transition name="animate-down">
          <div
            class="actions"
            :style="
              isSDK ? 'background-color:#F9FAFA' : 'background-color:#f7f7f7'
            "
          >
            <div class="add-actn-btn">
              <v-btn
                :color="isSDK ? '#C6CED2' : '#F9EED2'"
                style="text-transform: capitalize"
                light
                rounded
                depressed
                >Add action{{ exceptionValue ? "(s)" : "" }}</v-btn
              >
            </div>

            <v-timeline style="display: flex; justify-content: center">
              <div style="display: flex; margin-top: 10px">
                <v-btn
                  v-if="
                    (exceptionValue && selectedActions.length == 0) ||
                    (!exceptionValue && exceptionActions.length == 0)
                  "
                  fab
                  height="45"
                  @click="showActionModal"
                  width="45"
                  :dark="!isSDK"
                  :color="isSDK ? '#C6CED2' : '#D7A47B'"
                  depressed
                  style="margin: auto"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-timeline>

            <!-- <div v-sortable="{ onUpdate: reOrder }"> -->

            <div>
              <draggable
                v-model="selectedActions"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
              >
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <template v-if="exceptionValue">
                    <action-workflow
                      v-for="(action, index) in selectedActions"
                      :isLast="index == selectedActions.length - 1"
                      :isSDK="isSDK"
                      :apiKey="apiKey"
                      :key="index"
                      :organizationId="organizationId"
                      :trigger="trigger"
                      :isBeenDragged="drag"
                      :index="index"
                      :add-action="true"
                      v-model="selectedActions[index]"
                      @channel="channels.splice(index, 1, $event)"
                      @add-new-action="showActionModal"
                      @remove-action="removeAction(index)"
                      :exception="exceptionValue"
                      :inputs="inputs"
                      :split-array="splittedArray"
                    />
                  </template>
                  <template v-else>
                    <action-workflow
                      v-for="(action, index) in exceptionActions"
                      :isLast="true"
                      :isSDK="isSDK"
                      :apiKey="apiKey"
                      :key="index"
                      :organizationId="organizationId"
                      :trigger="trigger"
                      :isBeenDragged="drag"
                      :index="index"
                      :add-action="exceptionActions.length == 0"
                      v-model="exceptionActions[index]"
                      @channel="exceptionChannels.splice(index, 1, $event)"
                      @remove-action="removeAction(index)"
                      :exception="exceptionValue"
                      :inputs="inputs"
                    />
                  </template>
                </transition-group>
              </draggable>
            </div>
          </div>
        </transition>
      </div>

      <!-- <div style="margin-top: 25px; width: 150px">
        <v-btn
          color="primary"
          elevation="0"
          :disabled="!disablePublish"
          large
          @click="$emit('publish')"
          ><v-icon>mdi-chevron-right</v-icon> publish</v-btn
        >
      </div> -->
      <div class="bottom-btn">
        <v-btn
          color="primary"
          @click="$emit('back')"
          text
          elevation="0"
          v-if="isSDK"
        >
          <v-icon left>mdi-chevron-left</v-icon> Back
        </v-btn>

        <v-btn
          @click="$emit('publish')"
          :disabled="disablePublish"
          large
          elevation="0"
          :color="isSDK ? '#19283D' : 'primary'"
          :dark="isSDK"
        >
          publish
          <v-icon size="27" v-if="!isSDK" right>mdi-chevron-down</v-icon>
          <v-icon v-else right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>

    <v-navigation-drawer
      width="360px"
      v-model="actionModal"
      right
      temporary
      app
    >
      <div class="action-drawer">
        <span class="title">Actions</span>
        <span class="desc">
          Select from list of actions below. Selected action is added to your
          actions canvas within your workflow
        </span>
        <div>
          <v-text-field
            name="Search"
            label="search"
            hide-details="auto"
            v-model="searchQuery"
            color="primary"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </div>
        <div class="content">
          <div
            class="content__action"
            v-for="(action, index) in finalActions"
            :key="index"
            @click="addAction(action)"
          >
            <img class="actions-icon" :src="action.icon" :alt="action.type" />
            <span>{{ action.text }}</span>
            <span v-if="!action.active" class="coming-soon">Coming soon</span>
          </div>
        </div>
      </div>
      <!-- -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import actionWorkflow from "./action-workflow.vue";
import draggable from "vuedraggable";
export default {
  components: { actionWorkflow, draggable },
  props: {
    isVisable: {
      type: Boolean,
      default: false,
    },
    value: {
      default: [],
    },
    trigger: {
      default: null,
    },
    isSDK: {
      type: Boolean,
      default: false,
    },
    apiKey: {
      type: String,
      default: null,
    },

    organizationId: {
      type: String,
      default: null,
    },
    exception: {
      type: Boolean,
      default: false,
    },

    exceptions: {
      type: Object,
      default: null,
    },
    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showTriggers: false,
      actionModal: false,
      searchQuery: "",
      selectedActions: [],
      exceptionActions: [],
      channels: [],
      exceptionChannels: [],
      drag: false,
      scrollOptions: {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
        container: ".flows",
      },
      exceptionValue: false,
    };
  },

  mounted() {
    this.getAllActions();
    if (this.isSDK) {
      this.showTriggers = true;
    } else {
      this.$store.dispatch("organizations/fetchCoWorkers");
      this.$store.dispatch("organizations/fetchBanks");
      this.$store.dispatch("formBuilder/FetchAllForms");
      this.$store.dispatch("workflow/getAllWorkflows");
    }
  },
  methods: {
    onUpdate: function (event) {
      this.list.splice(
        event.newIndex,
        0,
        this.list.splice(event.oldIndex, 1)[0]
      );
    },
    showActionModal() {
      this.actionModal = true;
    },

    async getAllActions() {
      await this.$store.dispatch("workflow/getAllWorkflowActions", {
        apiKey: this.apiKey,
      });

      if (this.selectedActions.length > 0 && this.actionsMeta.length > 0) {
        this.showTriggers = true;
      }
    },

    addAction(action) {
      if (!action.active) {
        return;
      }
      this.actionModal = false;
      if (this.exceptionValue) {
        this.selectedActions.push({ ...action.meta, fresh: true });
      } else {
        this.exceptionActions.push({ ...action.meta, fresh: true });
      }
    },
    removeAction(index) {
      if (this.exceptionValue) {
        this.selectedActions.splice(index, 1);
        this.channels.splice(index, 1);
      } else {
        this.exceptionActions.splice(index, 1);
        this.exceptionChannels.splice(index, 1);
      }
    },
    fetchActions() {
      this.isLoadingFormFields = true;
      setTimeout(() => {
        this.isLoadingFormFields = false;
      }, 1000);
    },
  },
  computed: {
    canAddAction() {
      if (this.exceptionValue) {
        return true;
      } else {
        return this.selectedActions.length < 1;
      }
    },
    filteredActions() {
      let actions = [];
      if (this.searchQuery !== "") {
        actions = this.actionsMeta
          .filter(
            (action) =>
              action?.meta?.trigger?.includes(this.trigger) && action != null
          )
          .filter((action) => {
            return action.text
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });
      } else {
        actions = this.actionsMeta.filter(
          (action) =>
            action?.meta?.trigger?.includes(this.trigger) && action != null
        );
      }

      // if object with type hyphenSplitpay is in selectedActions, then remove it from actions

      const isExists = this.selectedActions.find(
        (action) => action?.type === "hyphenSplitpay"
      );

      if (isExists) {
        actions = actions?.filter(
          (action) => action?.type !== "hyphenSplitpay"
        );
      }

      if (!this.exceptionValue) {
        return actions?.filter((action) => action?.meta?.exception);
      } else return actions;
    },

    finalActions() {
      return this.filteredActions;
      // if (!this.isSDK) {

      // } else {
      //   // replace @/assets/ with https://embed.hypn.so/images/workflow/
      //   return this.filteredActions?.map((action) => {
      //     action.icon = action.icon
      //       .replace(".svg", ".png")
      //       .replace("/img/", "https://embed.hypn.so/images/workflow/");

      //     return action;
      //   });
      // }
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    disablePublish() {
      const trueTabs =
        this.channels.every((action) => action) && this.channels.length > 0;
      const falseTabs =
        this.exceptionChannels.every((action) => action) &&
        this.exceptionChannels.length > 0;

      if (this.exceptionActions.length === 0) {
        return trueTabs === false;
      } else return trueTabs === false || falseTabs === false;
    },

    splittedArray() {
      const arrs = this.selectedActions.filter(
        (action) => action?.type === "hyphenSplitpay"
      );

      return arrs.map((arr) => {
        return {
          fieldValue: arr.properties.values[1],
          account: arr.properties.values[2],
          type: arr.properties.values[3],
        };
      });
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        // this.showTriggers = true;

        if (JSON.stringify(val) !== JSON.stringify(this.selectedActions)) {
          this.selectedActions = val;
          // console.log("acrions", val);
          // if (val.length > 0 && this.actionsMeta.length > 0) {
          // }
        }
      },
    },
    exceptions: {
      deep: true,
      immediate: true,
      handler(val) {
        // check if val is an array and take the first object o the array if not take the object
        if (Array.isArray(val)) {
          if (
            val.length > 0 &&
            JSON.stringify(val[0]) !==
              JSON.stringify(this.exceptionActions[0] && val !== null)
          ) {
            this.exceptionActions = [val[0]];
          }
        } else if (
          JSON.stringify(val) !== JSON.stringify(this.exceptionActions[0]) &&
          val != null
        ) {
          this.exceptionActions = [val];
        }

        // if(this.exceptionActions[0].length > 0 && JSON.stringify(val) !== JSON.stringify(this.exceptionActions[0]) &&
        //   val != null){
        //     this.exceptionActions = val;

        // }
      },
    },
    exception: {
      immediate: true,
      handler(val) {
        if (val !== this.exceptionValue) {
          this.exceptionValue = val;
        }
      },
    },
    exceptionValue: {
      immediate: true,
      handler(val) {
        this.$emit("exception", val);
      },
    },
    selectedActions: {
      deep: true,
      immediate: true,
      handler(newVal) {
        // check array for type is hyphenSplitpay

        // console.log(JSON.stringify(newVal, null, 2));

        if (newVal.length === 0) return;

        const splitArrays = newVal.find(
          (action) =>
            action?.type === "hyphenSplitpay" &&
            typeof Array.isArray(action?.properties?.values?.values)
        );

        const count = newVal.filter(
          (action) => action?.type === "hyphenSplitpay"
        ).length;

        // find index of hyphenSplitpay

        const index = newVal.findIndex(
          (action) =>
            action?.type === "hyphenSplitpay" &&
            typeof action?.properties?.values === "object"
        );

        // last working code
        // if (splitArrays && count === 1) {
        //   console.log(JSON.stringify(splitArrays, null, 2));
        //   const splitArray = splitArrays.properties?.values?.values.map(
        //     (value) => {
        //       return {
        //         type: "hyphenSplitpay",
        //         properties: {
        //           keys: ["organization", "value", "account", "type"],
        //           values: [
        //             splitArrays.properties.values.organization,
        //             value.value,
        //             value.account,
        //             value.type,
        //           ],
        //         },
        //       };
        //     }
        //   );

        //   console.log("before", JSON.stringify(this.selectedActions, null, 2));
        //   // this.selectedActions = splitArray;
        //   this.selectedActions.splice(index, 1, ...splitArray);

        //   console.log("after", JSON.stringify(this.selectedActions, null, 2));
        //   this.$emit("input", newVal);
        // } else {
        //   // remove all object with type hyphenSplitpay
        //   // this.selectedActions = this.selectedActions.filter(
        //   //   (action) => action.type !== "hyphenSplitpay"
        //   // );
        // }

        if (splitArrays && count === 1) {
          // console.log(JSON.stringify(splitArrays, null, 2));
          const splitArray = splitArrays?.properties?.values?.values?.map(
            (value) => {
              return {
                type: "hyphenSplitpay",
                properties: {
                  keys: ["organization", "value", "account", "type"],
                  values: [
                    splitArrays.properties.values.organization,
                    value.value,
                    value.account,
                    value.type,
                  ],
                },
              };
            }
          );

          // console.log("before", JSON.stringify(this.selectedActions, null, 2));
          // this.selectedActions = splitArray;
          this.selectedActions.splice(index, 1, ...splitArray);

          // console.log("after", JSON.stringify(this.selectedActions, null, 2));
        }

        this.$emit("input", newVal);
      },
    },
    showTriggers: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick();
          setTimeout(() => {
            this.$vuetify.goTo(this.$refs.action, this.scrollOptions);
          }, 1500);
        }
      },
    },
    actionModal(val) {
      if (!val) {
        this.searchQuery = "";
      }
    },

    exceptionActions: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!newVal && newVal.length === 0) return;
        this.$emit("exception-schema", newVal[0]);
      },
    },

    // finalActions: {
    //   deep: true,
    //   immediate: true,
    //   handler(val) {
    //     console.log(JSON.stringify(val, null, 2));
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped>
.vertical-line {
  display: block;
  background-color: #d9dee1;
  margin: auto;
  height: 80px;
  width: 2px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
}

.exception {
  width: 200px;
  margin: 0 auto;
  border-radius: 8px;

  button {
    width: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 54px;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.636364px;

    transition: all 0.3s ease-in-out;

    color: #000000;

    &.active {
      background: #fff;

      span {
        background: #2bd5ae;
      }
    }

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    span {
      background: #ff6a6a;
      color: #96a9cf;
    }
  }
}
.form-trigger {
  width: 100%;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;

  // mobile view
  @media (max-width: 768px) {
    padding: 10px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .title {
      font-weight: bold;
      color: var(--v-primary-base);
      font-size: 16px;
      display: block;
      text-transform: capitalize;
    }
    .text {
      display: block;
      font-size: 14px;
      color: rgba(25, 40, 61, 0.8);
      margin-top: 10px;
    }
  }
}
.actions {
  padding: 50px 0px;
  background-color: #f7f7f7;
  min-height: 400px;
  border-radius: 6px;
  margin-top: 30px;

  .add-actn-btn {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}
.action-drawer {
  padding: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: var(--v-primary-base);
    display: block;
  }
  .desc {
    font-size: 14px;
    color: #8f96a1;
    margin: 10px 0px;
    display: block;
    line-height: 22px;
  }
  .content::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  .content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  .content::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }
  .content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    flex: 1;
    overflow: auto;
    margin-top: 5px;
    &__action {
      padding: 0px 5px;
      cursor: pointer;
      margin: 20px 0px;
      &:hover {
        span {
          color: #d7a47b;
        }
      }
      img {
        width: 30px;
        height: 30px;
        display: block;
        margin: auto;
      }
      span {
        display: block;
        text-align: center;
        margin-top: 10px;
        color: #7f919b;
      }
      .coming-soon {
        font-family: "Inter";
        opacity: 0.9;
        margin-top: 1px;
        color: #d7a47b;
      }
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #f7f7f7;
}

.bottom-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  //mobile view
  @media (max-width: 600px) {
    gap: 10px;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
