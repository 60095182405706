<template>
  <div>
    <div class="vertical-line"></div>
    <div class="loader" v-if="isLoadingTypes">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else class="payment-trigger">
      <span class="header"
        >Select payment type which will trigger this workflow</span
      >

      <div class="wrapper">
        <div
          :style="!$vuetify.breakpoint.smAndDown ? 'max-width: 55%' : undefined"
        >
          <v-select
            outlined
            :disabled="isEdit"
            :items="items"
            v-model="selectedPayment"
            item-text="label"
            item-value="key"
            color="primary"
            hide-details="auto"
            placeholder="Select type"
          ></v-select>
        </div>
        <v-btn
          elevation="0"
          @click="proceed()"
          :disabled="selectedPayment == null"
          :color="isSDK ? '#19283D' : 'primary'"
          :dark="isSDK"
        >
          <v-icon v-if="!isSDK" left>mdi-arrow-down</v-icon>
          Continue
          <v-icon v-if="isSDK" right>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },

    isVisable: {
      type: Boolean,
      default: false,
    },

    isEdit: {
      default: false,
    },

    isSDK: {
      type: Boolean,
      default: false,
    },

    apiKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedPayment: null,
      isLoadingTypes: false,
      items: [],
      proceeded: false,
    };
  },
  methods: {
    async fetchPaymentTypes() {
      try {
        this.isLoadingTypes = true;
        const options = await this.$store.dispatch("workflow/getPaymentTypes", {
          apiKey: this.apiKey,
          isSDK: this.isSDK,
        });
        this.items = options;
      } catch (err) {
        this.showToast({
          sclass: "error",
          show: true,
          message: err.msg,
          timeout: 3000,
        });
      } finally {
        this.isLoadingTypes = false;
      }
    },

    proceed() {
      this.proceeded = true;
      this.$emit("input", this.selectedPayment);
      this.$emit("next");
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== this.selectedPayment && val !== null) {
          this.selectedPayment = val;
        }
      },
    },

    isVisable: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchPaymentTypes();
        }
      },
    },

    selectedPayment() {
      if (this.proceeded) {
        this.$emit("input", null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // mobile view
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.vertical-line {
  display: block;
  background-color: #d9dee1;

  margin: auto;
  height: 80px;
  width: 2px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
}

.payment-trigger {
  width: 100%;

  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;

  @media (max-width: 600px) {
    padding: 10px;
  }

  .header {
    display: block;
    font-size: 16px;
    color: rgba(25, 40, 61, 0.8);
  }
}
</style>
