<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img
            class="i"
            :src="require('@/assets/actions-setup-billing.svg')"
            alt=""
          />
          <span class="t">Setup Billing</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f7f7f7'"
      >
        <div class="top">
          <span
            @click="actionStep = 1"
            class="action-title"
            :class="{ active: actionStep == 1 }"
            >BILLING</span
          >
          <span
            @click="actionStep = 2"
            class="action-title"
            :class="{ active: actionStep == 2 }"
            >CUSTOMER</span
          >
        </div>

        <span class="action-description">
          <template v-if="actionStep == 1">
            Set your invoice preference here, and hyphen will automatically
            generate and send an invoice to the customer whose data is sent to
            this workflow
          </template>
          <template v-if="actionStep == 2">
            Select or reference the customer and enter a description for this
            invoice.
          </template>
        </span>

        <div class="a-wrapper">
          <v-row v-if="actionStep == 1">
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-combobox
                    v-model="form.amount"
                    color="primary"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :items="
                      (inputs &&
                        inputs.fields.filter(
                          (i) => i.type === 'money' || i.type === 'number'
                        )) ||
                      []
                    "
                    @blur="formatAmount"
                    item-text="label"
                    item-value="key"
                    placeholder="@amount"
                    outlined
                    hide-details="auto"
                  >
                    <template slot="append">
                      <v-icon class="pl-2">mdi-menu-down</v-icon>
                    </template>
                  </v-combobox>
                </v-col>

                <v-col cols="6">
                  <v-select
                    outlined
                    color="primary"
                    v-model="form.pricing"
                    :items="pricingModels"
                    hide-details="auto"
                    placeholder="Choose Pricing Model"
                  ></v-select>
                </v-col>

                <template v-if="form.pricing === 'Per Unit'">
                  <v-col cols="6">
                    <v-select
                      outlined
                      color="primary"
                      label="Unit Source"
                      v-model="form.unit"
                      :items="unitSources"
                      hide-details="auto"
                      type="number"
                      placeholder="Choose"
                    ></v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      outlined
                      color="primary"
                      label="Unit Value"
                      v-model="unitValue"
                      hide-details="auto"
                      placeholder="@field"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="form.pricing === 'Per Unit'">
              <div class="bill-cal">
                <img
                  :src="
                    isSDK
                      ? 'https://embed.hypn.so/images/workflow/invoice-flag.png'
                      : require('@/assets/invoice-flag.svg')
                  "
                />
                <span>
                  Bill/Invoice total will be dynamically calculated as <br />
                  <b>(unit value x amount) </b>
                </span>
              </div>
            </v-col>

            <v-col cols="12">
              <div style="display: flex; gap: 20px">
                <v-switch label="TAX" v-model="form.tax"></v-switch>
                <v-switch
                  label="Send invoice"
                  v-model="form.sendinvoice"
                ></v-switch>
                <v-switch
                  label="Send Reminder"
                  v-model="form.sendreminder"
                ></v-switch>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="actionStep == 2">
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    color="primary"
                    label="Customer"
                    v-model="form.customer"
                    hide-details="auto"
                    placeholder="@field"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-select
                    outlined
                    color="primary"
                    label="Customer Id"
                    v-model="form.customerid"
                    :items="customerIdType"
                    hide-details="auto"
                    placeholder="Choose"
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    outlined
                    color="primary"
                    label="Due Date (days)"
                    v-model="form.duedate"
                    :items="dueDates"
                    hide-details="auto"
                    placeholder="Choose"
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    outlined
                    color="primary"
                    label="Bill Interval"
                    v-model="form.interval"
                    :items="billIntervals"
                    hide-details="auto"
                    type="number"
                    placeholder="Choose"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-text-field
                outlined
                color="primary"
                label="Description"
                v-model="form.description"
                hide-details="auto"
                placeholder="@description"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            v-if="actionStep == 1"
            @click="actionStep = 2"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Next
          </v-btn>

          <v-btn
            large
            v-else
            @click="addToWorkflow"
            :disabled="!canAddToWorkflow"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenSendBilling",
        properties: {
          keys: [
            "amount",
            "unit",
            "unitvalue",
            "pricing",
            "tax",
            "sendinvoice",
            "sendreminder",
            "customer",
            "customerid",
            "duedate",
            "interval",
            "description",
          ],
          values: ["", "", "", "", "true", "true", "false", "", "", "", "", ""],
        },
      },
    },

    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },

    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actionStep: 1,
      dialog: false,

      form: {
        amount: "",
        unit: "",
        unitvalue: "",
        pricing: "Flat",
        tax: true,
        sendinvoice: true,
        sendreminder: false,
        customer: "",
        customerid: "",
        duedate: "",
        interval: "",
        description: "",
      },

      pricingModels: ["Flat", "Per Unit"],
      unitSources: ["Field", "Static"],
      customerIdType: ["email", "hypn id", "phone"],
      dueDates: ["Instant", "15", "30", "45", "60", "90"],
      billIntervals: ["one time", "weekly", "monthly"],
    };
  },
  mounted() {
    this.mapForm();
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenSendBilling",
        properties: {
          keys: [
            "amount",
            "unit",
            "unitvalue",
            "pricing",
            "tax",
            "sendinvoice",
            "sendreminder",
            "customer",
            "customerid",
            "duedate",
            "interval",
            "description",
          ],
          values: [
            this.form.amount.key !== undefined
              ? "@" + this.form.amount.key
              : this.form.amount,
            this.form.unit?.toString() || "",
            this.form.pricing == "Flat" ? "" : this.form.unitvalue || "",
            this.form.pricing || "",
            this.form.tax ? "true" : "false",
            this.form.sendinvoice ? "true" : "false",
            this.form.sendreminder ? "true" : "false",
            this.form.customer || "",
            this.form.customerid || "",
            this.form.duedate
              ? this.form.duedate === "Instant"
                ? "0"
                : this.form.duedate
              : "",
            this.form.interval || "",
            this.form.description || "",
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();

      this.close();
    },

    mapForm() {
      this.form.amount =
        this.value.properties.values[
          this.value.properties.keys.indexOf("amount")
        ]?.replace("@", "") || "";

      this.form.unit =
        this.value.properties.values[
          this.value.properties.keys.indexOf("unit")
        ] || "";

      this.form.unitvalue =
        this.value.properties.values[
          this.value.properties.keys.indexOf("unitvalue")
        ] || "";

      this.form.pricing =
        this.value.properties.values[
          this.value.properties.keys.indexOf("pricing")
        ] || "Flat";

      this.form.tax =
        this.value.properties.values[
          this.value.properties.keys.indexOf("tax")
        ] === "true"
          ? true
          : false;

      this.form.sendinvoice =
        this.value.properties.values[
          this.value.properties.keys.indexOf("sendinvoice")
        ] === "true"
          ? true
          : false;

      this.form.sendreminder =
        this.value.properties.values[
          this.value.properties.keys.indexOf("sendreminder")
        ] === "true"
          ? true
          : false;

      this.form.customer =
        this.value.properties.values[
          this.value.properties.keys.indexOf("customer")
        ] || "";

      this.form.customerid =
        this.value.properties.values[
          this.value.properties.keys.indexOf("customerid")
        ] || "";

      // if value of duedate is 0, set it to instant
      this.form.duedate =
        this.value.properties.values[
          this.value.properties.keys.indexOf("duedate")
        ] === "0"
          ? "Instant"
          : this.value.properties.values[
              this.value.properties.keys.indexOf("duedate")
            ] || "";

      this.form.interval =
        this.value.properties.values[
          this.value.properties.keys.indexOf("interval")
        ] || "";

      this.form.description =
        this.value.properties.values[
          this.value.properties.keys.indexOf("description")
        ] || "";

      this.sendOutChannel();
    },

    sendOutChannel() {
      const val =
        this.form.pricing === "Flat"
          ? this.form.amount.key !== undefined
            ? "@" + this.form.amount.key
            : this.form.amount
          : !isNaN(this.form.amount)
          ? this.form.amount
          : isNaN(this.form.unitvalue)
          ? this.form.amount
          : this.form.amount * this.form.unitvalue;

      this.$emit(
        "channel",
        !isNaN(parseFloat(val))
          ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : val || "N/A"
      );
    },

    formatAmount() {
      let amount = this.form.amount.toString();
      amount = amount.replace(/,/g, "");

      if (!isNaN(parseFloat(amount))) {
        amount = this.addKobo(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.form.amount = amount;
      }
    },

    addKobo(target) {
      let val = target;

      if (isNaN(parseFloat(target))) {
        return val;
      }

      // const regex = /\.\d{2}$/;

      // check if target ends with .
      if (target.endsWith(".")) {
        const newV = target + "00";
        val = newV.trim();
      } else if (target.indexOf(".") == -1) {
        const newV = target + ".00";
        val = newV.trim();
      } else {
        // already has dot
        const newV = parseFloat(target).toFixed(2);
        val = newV.trim();
      }

      // count number of dot in target
      const dotCount = (val.match(/\./g) || []).length;
      if (dotCount > 1) {
        // remove everything after the first dot
        val = val.slice(0, val.indexOf(".") + 1);
      }

      return val;
    },
  },
  computed: {
    canAddToWorkflow() {
      return (
        this.form.amount &&
        this.form.pricing &&
        (this.form.pricing === "Per Unit"
          ? this.form.unitvalue && this.form.unit
          : true) &&
        this.form.customer &&
        this.form.customerid &&
        this.form.duedate &&
        this.form.interval &&
        this.form.description
      );
    },

    // amount: {
    //   get() {
    //     return isNaN(this.form.amount)
    //       ? this.form.amount
    //       : this.form.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   },

    //   set(val) {
    //     this.form.amount = val.replace(/,/g, "");
    //     // this.sendOutChannel();
    //   },
    // },

    unitValue: {
      get() {
        return isNaN(this.form.unitvalue)
          ? this.form.unitvalue
          : this.form.unitvalue
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      set(val) {
        this.form.unitvalue = val.replace(/,/g, "");
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f7f7f7;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        cursor: pointer;
        color: #8f96a1;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        &.active {
          border-bottom: 5px solid var(--v-primary-base);
          color: var(--v-primary-base);
        }

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }

    .bill-cal {
      display: flex;
      align-items: center;
      background: #ffffff;
      border-left: 4px solid #5b67ba;
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      padding: 25px;
      gap: 25px;
      margin: 20px 0px;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        /* or 150% */

        /* hyphen Blue */

        color: #19283d;
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
