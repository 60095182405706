<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img
            class="i"
            :src="require('@/assets/actions-update-customer.svg')"
            alt=""
          />
          <span class="t">Generate Invoice</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f7f7f7'"
      >
        <div class="top">
          <span class="action-title">INVOICE</span>
        </div>

        <span class="action-description">
          <!-- Select a vendor attribute to update -->
        </span>

        <div class="a-wrapper">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    color="primary"
                    label="Email"
                    v-model="email"
                    hide-details="auto"
                    placeholder="@email"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    color="primary"
                    label="Amount"
                    v-model="amount"
                    hide-details="auto"
                    type="number"
                    placeholder="@amount"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="description"
                color="primary"
                label="Description"
                hide-details="auto"
                placeholder="@description"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <div style="display: flex; gap: 20px">
                <v-switch label="VAT" v-model="vat"></v-switch>
                <v-switch label="Send invoice" v-model="send"></v-switch>
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            @click="addToWorkflow"
            :disabled="!canAddToWorkflow"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenSendInvoice",
        properties: {
          keys: [
            "name",
            "email",
            "amount",
            "description",
            "date",
            "tax",
            "send",
          ],
          values: ["invoice", "", "", "", "", "", ""],
        },
      },
    },

    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      email: "",
      amount: "",
      description: "",
      date: "",
      vat: false,
      send: false,
    };
  },
  mounted() {
    this.mapForm();
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenSendInvoice",
        properties: {
          keys: [
            "name",
            "email",
            "amount",
            "description",
            "date",
            "tax",
            "send",
          ],
          values: [
            "invoice",
            this.email || "@email",
            this.amount?.toString() || "@amount",
            this.description || "@description",
            this.date,
            this.vat + "",
            this.send + "",
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();

      this.close();
    },

    mapForm() {
      this.email =
        this.value.properties.values[
          this.value.properties.keys.indexOf("email")
        ] || "";

      this.amount = this.value.properties.values[
        this.value.properties.keys.indexOf("amount")
      ]
        ? Number(
            this.value.properties.values[
              this.value.properties.keys.indexOf("amount")
            ]
          )
        : "";

      this.description =
        this.value.properties.values[
          this.value.properties.keys.indexOf("description")
        ] || "";

      this.date =
        this.value.properties.values[
          this.value.properties.keys.indexOf("date")
        ] || "";

      const tempVat =
        this.value.properties.values[this.value.properties.keys.indexOf("tax")];

      this.vat = tempVat === "true" ? true : false;

      const tempSend =
        this.value.properties.values[
          this.value.properties.keys.indexOf("send")
        ];

      this.send = tempSend === "true" ? true : false;

      this.sendOutChannel();
    },

    sendOutChannel() {
      this.$emit(
        "channel",
        this.amount
          ? this.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "N/A"
      );
    },
  },
  computed: {
    canAddToWorkflow() {
      return this.email && this.amount;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f7f7f7;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
