var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"650px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"action"},[_c('div',{staticClass:"action__header"},[_c('div',{staticClass:"b"},[_c('img',{staticClass:"i",attrs:{"src":require('@/assets/action-expense.svg'),"alt":""}}),_c('span',{staticClass:"t"},[_vm._v("Add Expense")])]),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"action__content",style:(_vm.isSDK ? 'background-color:#F9FAFA' : 'background-color:#f7f7f7')},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"action-title"},[_vm._v("Expense")])]),_c('span',{staticClass:"action-description"},[_vm._v("Capture expense request automatically before it is approved and added to payables")]),_c('div',{staticClass:"a-wrapper"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'text' ||
                      i.type === 'email' ||
                      i.type === 'input'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Requester","outlined":"","hide-details":"auto"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'money' || i.type === 'number'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Amount","outlined":"","hide-details":"auto"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'text' || i.type === 'input'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Description","outlined":"","hide-details":"auto"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'text' || i.type === 'input'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"Subtotal (optional)","outlined":"","hide-details":"auto"},model:{value:(_vm.form.subtotal),callback:function ($$v) {_vm.$set(_vm.form, "subtotal", $$v)},expression:"form.subtotal"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"color":"primary","menu-props":{ bottom: true, offsetY: true },"items":(_vm.inputs &&
                  _vm.inputs.fields.filter(
                    function (i) { return i.type === 'number' || i.type === 'input'; }
                  )) ||
                [],"item-text":"label","item-value":"key","placeholder":"VAT (optional)","outlined":"","hide-details":"auto"},model:{value:(_vm.form.vat),callback:function ($$v) {_vm.$set(_vm.form, "vat", $$v)},expression:"form.vat"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"pl-2"},[_vm._v("mdi-menu-down")])],1)],2)],1)],1)],1),_c('div',{staticClass:"bottom"},[_c('v-btn',{attrs:{"large":"","color":"primary","outlined":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel ")],1),_c('v-btn',{attrs:{"large":"","color":_vm.isSDK ? '#19283D' : 'primary',"dark":_vm.isSDK,"elevation":"0","disabled":!_vm.canAddToWorkflow},on:{"click":_vm.addToWorkflow}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" Add to workflow ")],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }