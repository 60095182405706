<template>
  <v-dialog
    v-model="dialog"
    max-width="650px"
    width="90%"
    transition="dialog-transition"
  >
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img class="i" :src="require('@/assets/actions-split-pay.svg')" />
          <span class="t">Split Pay</span>
        </div>
        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f7f7f7'"
      >
        <div class="top">
          <span class="action-title">SPLIT-RULE</span>
        </div>
        <span class="action-description">
          Split pay can only disburse payment into account you own and have
          added to your Hyphen settings.
          <span @click="launchMono"> +add bank account </span>

          <br />

          <br />
          <p>
            <b>
              Use the options below to setup your split rule. Split rule
              determines how a payment can be divided and settled into your
              accounts.
            </b>
          </p>
        </span>

        <div class="a-wrapper">
          <!-- {{ inputs.fields }} -->
          <template v-if="value && value.fresh">
            <v-row
              v-for="(rule, index) in rules"
              :key="index"
              style="align-items: center; margin-top: 10px"
              no-gutters
            >
              <v-col cols="12" md="4">
                <v-select
                  v-model="fieldType"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="types"
                  item-text="label"
                  item-value="value"
                  style="
                    background: #ffffff;
                    box-sizing: border-box;
                    border-radius: 3px;
                  "
                  class="justify-center mr-2"
                  flat
                  outlined
                  hide-details="auto"
                >
                  <template slot="prepend">
                    <span
                      style="
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 24px;
                        color: rgba(25, 40, 61, 0.8);
                      "
                      >Transfer</span
                    >
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-combobox
                  color="#D7A47B"
                  id="operators"
                  v-model="rules[index].fieldValue"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="(inputs && inputs.fields) || []"
                  item-text="label"
                  item-value="key"
                  class="justify-center mr-2"
                  flat
                  outlined
                  hide-details="auto"
                  style="box-sizing: border-box; border-radius: 3px"
                  :style="
                    isSDK
                      ? 'background: #F9FAFA'
                      : 'background:  #FDF8EE; color:#D7A47B'
                  "
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="rules[index].account"
                  :items="banks"
                  item-text="account_number"
                  item-value="hypn_id"
                  hide-details="auto"
                  class="mr-1"
                  :loading="isLoadingBanks"
                  outlined
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="1">
                <v-icon
                  @click="removeRule(index)"
                  tag="button"
                  plain
                  :color="isSDK ? 'grey' : 'error'"
                >
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>

            <button id="add-rule-btn" v-if="rules.length < 3" @click="addRule">
              +add new rule
            </button>
          </template>
          <template v-else>
            <v-row style="align-items: center; margin-top: 10px" no-gutters>
              <v-col cols="12" md="4">
                <v-select
                  v-model="form.type"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="types"
                  item-text="label"
                  item-value="value"
                  style="
                    background: #ffffff;
                    box-sizing: border-box;
                    border-radius: 3px;
                  "
                  class="justify-center mr-2"
                  flat
                  outlined
                  disabled
                  hide-details="auto"
                >
                  <template slot="prepend">
                    <span
                      style="
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 24px;
                        color: rgba(25, 40, 61, 0.8);
                      "
                      >Transfer</span
                    >
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-combobox
                  color="#D7A47B"
                  id="operators"
                  v-model="form.fieldValue"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="(inputs && inputs.fields) || []"
                  item-text="label"
                  item-value="key"
                  class="justify-center mr-2"
                  flat
                  outlined
                  hide-details="auto"
                  style="box-sizing: border-box; border-radius: 3px"
                  :style="
                    isSDK
                      ? 'background: #F9FAFA'
                      : 'background:  #FDF8EE; color:#D7A47B'
                  "
                >
                  <template slot="append">
                    <v-icon class="pl-2">mdi-menu-down</v-icon>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="5">
                <v-select
                  v-model="form.account"
                  :items="banks"
                  item-text="account_number"
                  item-value="hypn_id"
                  hide-details="auto"
                  class="mr-1"
                  :loading="isLoadingBanks"
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
          </template>
        </div>

        <div class="note">
          <img
            :src="
              isSDK
                ? 'https://embed.hypn.so/images/workflow/invoice-flag.png'
                : require('@/assets/invoice-flag.svg')
            "
          />
          <span>
            <b>Note:</b> Each rule is a disbursement to your bank account and
            will affect your wallet balance and expense statement
          </span>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            @click="addToWorkflow"
            large
            :disabled="!canAdd"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenSplitpay",
        properties: {
          keys: ["organization", "value", "account", "type"],
          values: ["", "", "", ""],
        },
      },
    },
    trigger: {
      default: null,
    },

    isSDK: {
      type: Boolean,
      default: false,
    },

    organizationId: {
      type: String,
      default: null,
    },

    inputs: {
      type: Object,
      default: () => {},
    },

    splitArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        type: "",
        fieldValue: "",
        account: "",
      },
      fieldType: "",
      isLoadingBanks: false,
      types: [
        { value: "percentage", label: "%" },
        { value: "amount", label: "₦" },
      ],
      rules: [
        {
          type: "",
          fieldValue: "",
          account: "",
        },
      ],
    };
  },
  mounted() {
    this.mapForm();
    // if (!this.isSDK) {
    //   this.getBanks();
    // }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    removeRule(index) {
      if (this.rules.length > 1) {
        this.rules.splice(index, 1);
      }
    },

    addRule() {
      this.rules.push({
        type: "",
        fieldValue: "",
        account: "",
      });
    },

    // async getBanks() {
    //   try {
    //     this.isLoadingBanks = true;
    //     const response = await this.$store.dispatch("organizations/fetchBanks");
    //     this.banks = response;

    //     this.sendOutChannel();
    //   } catch (err) {
    //     console.log(JSON.stringify(err, null, 2));
    //   } finally {
    //     this.isLoadingBanks = false;
    //   }
    // },

    addToWorkflow() {
      var payload;
      if (this.value && this.value?.fresh) {
        payload = {
          type: "hyphenSplitpay",
          properties: {
            keys: ["organization", "value", "account", "type"],
            values: {
              organization: this.organizationId,
              values: this.rules.map((rule) => {
                return {
                  value:
                    rule.fieldValue.key !== undefined
                      ? "@" + rule.fieldValue.key
                      : rule.fieldValue,
                  account: rule.account,
                  type: this.fieldType,
                };
              }),
            },
          },
        };
      }

      if (this.value && !this.value.fresh) {
        payload = {
          type: "hyphenSplitpay",
          properties: {
            keys: ["organization", "value", "account", "type"],
            values: [
              this.organizationId,
              this.form.fieldValue.key
                ? "@" + this.form.fieldValue.key !== undefined
                : this.form.fieldValue,
              this.form.account,
              this.form.type,
            ],
          },
        };
      }

      //   const payload = this.rules.map((rule) => {
      //     return {
      //       type: "hyphenSplitpay",
      //       properties: {
      //         keys: ["organization", "value", "account", "type"],
      //         values: [
      //           this.organizationId,
      //           "@" + (rule.fieldValue.key || rule.fieldValue),
      //           rule.account,
      //           rule.type,
      //         ],
      //       },
      //     };
      //   });

      // loop through rules and add  payload

      //   for (let i = 0; i < payload.length; i++) {
      //     this.$emit("split=", payload[i]);
      //   }

      //   console.log("split valuess", JSON.stringify(payload, null, 2));

      this.$emit("input", payload);

      this.sendOutChannel();
      this.close();
    },
    sendOutChannel() {
      if (this.value && this.value?.fresh) {
        // spilt out the first rule and send it out
        this.$emit(
          "channel",
          this.rules[0].fieldValue.key !== undefined
            ? "@" + this.rules[0].fieldValue.key
            : this.rules[0].fieldValue
        );
      } else {
        this.$emit(
          "channel",
          this.form.fieldValue.key !== undefined
            ? "@" + this.form.fieldValue.key
            : this.form.fieldValue
        );
      }
    },
    mapForm() {
      if (this.value && !this.value?.fresh) {
        // set to
        var fieldValue =
          this.value.properties.values[
            this.value.properties.keys.indexOf("value")
          ] || "";

        if (fieldValue.indexOf("@") === 0) {
          fieldValue = fieldValue.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === fieldValue);
          if (input) {
            this.form.fieldValue = input;
          } else {
            this.form.fieldValue = fieldValue;
          }
        } else {
          this.form.fieldValue = fieldValue;
        }

        this.form.account =
          this.value.properties.values[
            this.value.properties.keys.indexOf("account")
          ] || "";
        this.form.type =
          this.value.properties.values[
            this.value.properties.keys.indexOf("type")
          ] || "";
        this.sendOutChannel();
      }
    },
    async submitBankCode(response) {
      await this.$store.dispatch("organizations/addBank", response);
    },

    //method to launch the Mono app
    launchMono() {
      const options = {
        onSuccess: (response) => {
          this.submitBankCode(response);

          this.$store.dispatch("organizations/fetchBanks");
        },

        onClose: function () {
          console.log("user closed the widget.");
        },
      };
      this.$launchMono(options);
    },
  },

  computed: {
    banks() {
      return this.$store.getters["organizations/getOrganizationBanks"];
    },

    canAdd() {
      if (this.value && this.value?.fresh) {
        // check if all rules are filled, if rules length is greater than 0 and fieldType is not empty
        return (
          this.rules.length > 0 &&
          this.rules.every((rule) => {
            return rule.fieldValue && rule.account && this.fieldType;
          }) === true
        );
      } else {
        return (
          (this.form.fieldValue !== "" &&
            this.form.account !== "" &&
            this.form.type !== "") === true
        );
      }
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },

    // splitArray: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     if (val.length > 0) {
    //       //   this.rules = [];

    //       //   const vv = val.map((action) => {
    //       //     return {
    //       //       type: action.type,
    //       //       fieldValue: action.fieldValue,
    //       //       account: action.account,
    //       //     };
    //       //   });

    //       this.rules = val;
    //     }
    //   },
    // },

    // banks: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     if (val && val.length > 0) {
    //       this.workers = val.map((worker) => {
    //         return {
    //           id: worker.id,
    //           name: worker.first_name + " " + worker.last_name,
    //           email: worker.email,
    //         };
    //       });
    //       this.sendOutChannel();
    //     }
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f7f7f7;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;

      span {
        color: var(--v-primary-base);
        text-decoration: underline;
      }
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px 12px;
      border: 1px solid #d9dee1;
      border-radius: 4px;

      #add-rule-btn {
        margin: 20px 0px 0px 0px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #19283d;
      }
    }
    .note {
      display: flex;
      align-items: center;
      background: #ffffff;
      border-left: 4px solid #5b67ba;
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      padding: 25px;
      gap: 25px;
      margin: 20px 0px;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        /* or 150% */

        /* hyphen Blue */

        color: #19283d;
      }
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
