<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img class="i" :src="require('@/assets/actions-send-payment.svg')" />
          <span class="t">Send Payment</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f7f7f7'"
      >
        <div class="top">
          <span class="action-title">PAYMENT</span>
          <div>
            <span class="bal-title">Wallet Balance</span>
            <span class="bal">{{ walletBalance }}</span>
          </div>
        </div>

        <span class="action-description">
          Payment will be made to the vendor data within this workflow, if no
          vendor data is found at the time of execution the workflow will be
          terminated at this point.
        </span>

        <div class="a-wrapper">
          <div class="payee">
            <div class="head">
              <img src="@/assets/actions-update-vendor.svg" alt="update" />
              <span>Payee Info</span>
            </div>

            <v-btn icon color="#7F919B"><v-icon>mdi-chevron-up</v-icon></v-btn>
          </div>

          <v-radio-group v-model="form.payee_type" row>
            <v-radio
              color="primary"
              label="Verified vendor "
              value="vendor"
            ></v-radio>
            <v-radio color="primary" label="Staff" value="staff"></v-radio>
            <v-radio color="primary" label="All" value="both"></v-radio>
          </v-radio-group>

          <div>
            <v-row>
              <v-col cols="12" sm="6">
                <v-combobox
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'input' || i.type === 'text'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  hide-no-data
                  height="60px"
                  v-model="form.payee"
                  hide-details="auto"
                  placeholder="payee"
                  outlined
                  primary
                ></v-combobox>
              </v-col>

              <v-col cols="12" sm="6">
                <v-combobox
                  :items="
                    (inputs &&
                      inputs.fields.filter(
                        (i) => i.type === 'number' || i.type === 'money'
                      )) ||
                    []
                  "
                  item-text="label"
                  item-value="key"
                  hide-no-data
                  height="60px"
                  v-model="form.invoice_total"
                  hide-details="auto"
                  placeholder="Payment total"
                  outlined
                  primary
                ></v-combobox>
              </v-col>
            </v-row>
          </div>
        </div>
        <br />

        <div class="a-wrapper2">
          <div class="paymentType">
            <div class="option">
              <v-radio-group v-model="form.paymentType">
                <v-radio
                  color="primary"
                  label="full payment"
                  value="full"
                ></v-radio>
              </v-radio-group>
            </div>

            <div class="option">
              <v-radio-group v-model="form.paymentType">
                <v-radio
                  color="primary"
                  label="part payment"
                  value="part"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>

          <!-- <div v-if="paymentType == 'full'" class="fullPayment">
            <v-chip @click="when = 0" :color="when === 0 ? 'primary' : ''">
              <v-avatar>
                <v-icon>mdi-clock</v-icon>
              </v-avatar>
              Instant
            </v-chip>

            <v-chip
              @click="customDaysDialog = true"
              :color="when > 0 ? 'primary' : ''"
            >
              <v-avatar>
                <v-icon>mdi-clock</v-icon>
              </v-avatar>
              {{
                when === 0 || when === null
                  ? "set custom days"
                  : `In ${when} ${when === 1 ? "day" : "days"}`
              }}
            </v-chip>
          </div> -->

          <div v-if="form.paymentType == 'part'">
            <div class="fullPayment">
              <v-chip
                @click="form.part = 20"
                :color="form.part === 20 ? 'primary' : ''"
              >
                20%
              </v-chip>

              <v-chip
                @click="form.part = 50"
                :color="form.part === 50 ? 'primary' : ''"
              >
                50%
              </v-chip>

              <v-chip
                @click="partPaymentDialog = true"
                :color="
                  form.part === 20 || form.part === 50 || form.part === ''
                    ? ''
                    : 'primary'
                "
              >
                {{
                  form.part === 20 || form.part === 50 || form.part === ""
                    ? "Custom"
                    : form.part + "%"
                }}
              </v-chip>
            </div>
            <!-- <v-divider></v-divider>
            <div class="fullPayment">
              <v-chip
                @click="form.when = 0"
                :color="form.when === 0 ? 'primary' : ''"
              >
                <v-avatar>
                  <v-icon>mdi-clock</v-icon>
                </v-avatar>
                Instant
              </v-chip>

              <v-chip
                @click="customDaysDialog = true"
                :color="form.when > 0 ? 'primary' : ''"
              >
                <v-avatar>
                  <v-icon>mdi-clock</v-icon>
                </v-avatar>
                {{
                  form.when === 0 || form.when === null
                    ? "set custom days"
                    : `In ${form.when} ${form.when === 1 ? "day" : "days"}`
                }}
              </v-chip>
            </div> -->
          </div>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            @click="addToWorkflow"
            large
            :disabled="!canAddToWorkflow"
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="customDaysDialog"
      max-width="400px"
      transition="dialog-transition"
    >
      <div class="customDaysDialog">
        <v-text-field
          name="name"
          prepend-inner-icon="mdi-calendar"
          label="Number of days"
          placeholder="Enter number of days"
          type="number"
          outlined
          v-model="form.when"
        ></v-text-field>
      </div>
    </v-dialog>

    <v-dialog
      v-model="partPaymentDialog"
      max-width="400px"
      transition="dialog-transition"
    >
      <div class="customDaysDialog">
        <v-text-field
          name="name"
          label="Payment percentage"
          placeholder="Enter percentage"
          type="number"
          prefix="%"
          outlined
          v-model="form.part"
        ></v-text-field>
      </div>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: null,
    },
    trigger: {
      default: null,
    },
    isSDK: {
      default: false,
      type: Boolean,
    },

    inputs: {
      type: Object,
      default: () => {},
    },

    organizationId: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        invoice_total: "",
        payee_type: "",
        paymentType: "full",
        payee: "",
        when: null,
        part: "",
      },
      partPaymentDialog: false,
      customDaysDialog: false,
    };
  },
  mounted() {
    this.fetchTransactionSummary();
    this.mapForm();
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenSendPayment",
        properties: {
          keys: [
            "trigger",
            "invoice",
            "organization",
            "type",
            "payee_type",
            "payee",
            // "when",
            "part",
            "name",
          ],
          values: [
            this.trigger,
            this.form.invoice_total.key !== undefined
              ? "@" + this.form.invoice_total.key
              : this.form.invoice_total,
            this.organizationId,
            this.form.paymentType,
            this.form.payee_type,
            this.form.payee.key !== undefined
              ? "@" + this.form.payee.key
              : this.form.payee,
            // this.form.when.toString(),
            this.form.part.toString(),
            "payment",
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();
      this.close();
    },
    sendOutChannel() {
      let channel = this.form.paymentType === "full" ? "Full" : "Part" || "N/A";

      this.$emit("channel", channel);
    },

    mapForm() {
      if (this.value && !this.value?.fresh) {
        this.form.payee_type =
          this.value.properties.values[
            this.value.properties.keys.indexOf("payee_type")
          ] || "";

        this.form.paymentType =
          this.value.properties.values[
            this.value.properties.keys.indexOf("type")
          ] || "";

        // set payye
        var payee =
          this.value.properties.values[
            this.value.properties.keys.indexOf("payee")
          ] || "";

        if (payee.indexOf("@") === 0) {
          payee = payee.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === payee);
          if (input) {
            this.form.payee = input;
          } else {
            this.form.payee = payee;
          }
        } else {
          this.form.payee = payee;
        }

        // set payye
        var invoice_total =
          this.value.properties.values[
            this.value.properties.keys.indexOf("invoice_total")
          ] || "";

        if (invoice_total.indexOf("@") === 0) {
          invoice_total = invoice_total.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === invoice_total);
          if (input) {
            this.form.invoice_total = input;
          } else {
            this.form.invoice_total = invoice_total;
          }
        } else {
          this.form.invoice_total = invoice_total;
        }

        // set part
        this.form.part =
          this.value.properties.values[
            this.value.properties.keys.indexOf("part")
          ] || "";
      }
    },

    async fetchTransactionSummary() {
      await this.$store.dispatch(
        "transactions/transSummary",
        this.organizationId
      );
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.mapForm();
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
  },
  computed: {
    walletBalance() {
      return this.$summary
        ? Intl.NumberFormat("en-NG", {
            currency: "NGN",
            style: "currency",
          }).format(this.$summary.wallet)
        : "--";
    },

    canAddToWorkflow() {
      return this.form.payee_type && this.form.paymentType;
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f7f7f7;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }

    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }

      .bal-title {
        font-size: 13px;
        color: var(--v-primary-base);
        display: block;
      }

      .bal {
        font-size: 17px;
        color: var(--v-primary-base);
        font-weight: 600;
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;

      // mobile view
      @media (max-width: 600px) {
        padding: 10px;
      }
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .a-wrapper2 {
    padding: 0px;
  }

  .payee {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .head {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 25px;
        height: 25px;
        object-fit: fit;
      }
    }
  }

  .paymentType {
    display: flex;

    // mobile view
    @media (max-width: 600px) {
      flex-direction: column;
    }

    .option {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d9dee1;
    }
  }

  .fullPayment {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
}

.customDaysDialog {
  background-color: #f7f7f7;
  padding: 50px;
  border: 1px solid #d9dee1;
  border-radius: 4px;
}
</style>
