<template>
  <v-dialog v-model="dialog" max-width="650px" transition="dialog-transition">
    <div class="action">
      <div class="action__header">
        <div class="b">
          <img class="i" :src="require('@/assets/action-expense.svg')" alt="" />
          <span class="t">Add Expense</span>
        </div>

        <v-btn @click="close" icon color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        class="action__content"
        :style="isSDK ? 'background-color:#F9FAFA' : 'background-color:#f7f7f7'"
      >
        <div class="top">
          <span class="action-title">Expense</span>
        </div>

        <span class="action-description"
          >Capture expense request automatically before it is approved and added
          to payables</span
        >

        <div class="a-wrapper">
          <v-row>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.email"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) =>
                        i.type === 'text' ||
                        i.type === 'email' ||
                        i.type === 'input'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Requester"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.amount"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'money' || i.type === 'number'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Amount"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12">
              <v-combobox
                v-model="form.description"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'text' || i.type === 'input'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Description"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.subtotal"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'text' || i.type === 'input'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="Subtotal (optional)"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-combobox
                v-model="form.vat"
                color="primary"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="
                  (inputs &&
                    inputs.fields.filter(
                      (i) => i.type === 'number' || i.type === 'input'
                    )) ||
                  []
                "
                item-text="label"
                item-value="key"
                placeholder="VAT (optional)"
                outlined
                hide-details="auto"
              >
                <template slot="append">
                  <v-icon class="pl-2">mdi-menu-down</v-icon>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </div>

        <div class="bottom">
          <v-btn @click="close" large color="primary" outlined>
            <v-icon left>mdi-close</v-icon> Cancel
          </v-btn>

          <v-btn
            large
            :color="isSDK ? '#19283D' : 'primary'"
            :dark="isSDK"
            elevation="0"
            :disabled="!canAddToWorkflow"
            @click="addToWorkflow"
          >
            <v-icon left>mdi-chevron-right</v-icon> Add to workflow
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: {
        type: "hyphenAddExpense",
        properties: {
          keys: [
            "email",
            "amount",
            "description",
            "subtotal",
            "vat",
            "organization",
          ],
          values: [],
        },
      },
    },
    isSDK: {
      default: false,
      type: Boolean,
    },

    organizationId: {
      default: "",
      type: String,
    },

    inputs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      form: {
        email: "",
        amount: "",
        description: "",
        subtotal: "",
        vat: "",
      },
    };
  },
  mounted() {
    this.mapForm();
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    addToWorkflow() {
      const payload = {
        type: "hyphenAddExpense",
        properties: {
          keys: [
            "email",
            "amount",
            "description",
            "subtotal",
            "vat",
            "organization",
          ],
          values: [
            this.form.email.key === undefined
              ? this.form.email
              : "@" + this.form.email.key,
            this.form.amount.key === undefined
              ? this.form.amount
              : "@" + this.form.amount.key,
            this.form.description.key === undefined
              ? this.form.description
              : "@" + this.form.description.key,
            this.form.subtotal.key === undefined
              ? this.form.subtotal
              : "@" + this.form.subtotal.key,
            this.form.vat.key === undefined
              ? this.form.vat
              : "@" + this.form.vat.key,
            this.organizationId,
          ],
        },
      };

      this.$emit("input", payload);
      this.sendOutChannel();
      this.close();
    },

    sendOutChannel() {
      this.$emit(
        "channel",
        this.form.amount.key === undefined
          ? this.form.amount
          : "@" + this.form.amount.key
      );
    },

    mapForm() {
      if (this.value) {
        // set email
        var email =
          this.value.properties.values[
            this.value.properties.keys.indexOf("email")
          ] || "";
        if (email.indexOf("@") === 0) {
          email = email.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === email);
          if (input) {
            this.form.email = input;
          } else {
            this.form.email = email;
          }
        } else {
          this.form.email = email;
        }

        // set amount
        var amount =
          this.value.properties.values[
            this.value.properties.keys.indexOf("amount")
          ] || "";
        if (amount.indexOf("@") === 0) {
          amount = amount.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === amount);
          if (input) {
            this.form.amount = input;
          } else {
            this.form.amount = amount;
          }
        } else {
          this.form.amount = amount;
        }

        // set description
        var description =
          this.value.properties.values[
            this.value.properties.keys.indexOf("description")
          ] || "";

        if (description.indexOf("@") === 0) {
          description = description.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === description);
          if (input) {
            this.form.description = input;
          } else {
            this.form.description = description;
          }
        } else {
          this.form.description = description;
        }

        // set subtotal
        var subtotal =
          this.value.properties.values[
            this.value.properties.keys.indexOf("subtotal")
          ] || "";

        if (subtotal.indexOf("@") === 0) {
          subtotal = subtotal.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === subtotal);
          if (input) {
            this.form.subtotal = input;
          } else {
            this.form.subtotal = subtotal;
          }
        } else {
          this.form.subtotal = subtotal;
        }

        // set vat
        var vat =
          this.value.properties.values[
            this.value.properties.keys.indexOf("vat")
          ] || "";

        if (vat.indexOf("@") === 0) {
          vat = vat.slice(1);
          // get the object from the inputs
          const input = this.inputs.fields.find((i) => i.key === vat);
          if (input) {
            this.form.vat = input;
          } else {
            this.form.vat = vat;
          }
        } else {
          this.form.vat = vat;
        }

        this.sendOutChannel();
      }
    },
  },

  computed: {
    canAddToWorkflow() {
      return this.form.email && this.form.amount && this.form.description;
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
        this.mapForm();
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f7f7f7;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #d9dee1;
      border-radius: 4px;
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
